import * as React from 'react'
import {
  useTimedContent,
  Banner,
  BannerProps,
  PropsWithoutChildren,
} from 'src/design-system'
import { trackEvent } from '../services/event-tracker'
import { PackageProtect } from './package-protect'
import { can } from '../policies'
import { store } from 'store/index'
import { Info } from '@phosphor-icons/react'

export type FreemiumBannerProps = PropsWithoutChildren<
  Omit<BannerProps, 'onDismiss' | 'variant'>
>

export const FreemiumBanner: React.VFC<FreemiumBannerProps> = (props) => {
  const { show, hideFor } = useTimedContent('freemiumBannerShowAgainAfter')

  const user = store.nonNullCurrentUser
  const org = user.org

  if (!org || !show) return null

  const canEditOrg = can(user, org).edit

  const onDismiss = () => {
    hideFor({ days: 10 })
    trackEvent('Freemium upgrade banner dismissed')
  }

  const onClick = () => {
    trackEvent('$track_freemium_upgrade_freemium_banner_clicked')
  }

  return (
    <PackageProtect>
      <Banner
        onDismiss={onDismiss}
        variant={canEditOrg ? 'highlight' : 'warning'}
        {...props}
      >
        {canEditOrg ? (
          <>
            {'You need a paid plan to use Progression. '}
            <a
              className="font-semibold text-pink-700"
              href={`${org.basePath}/billing/new`}
              onClick={onClick}
            >
              Explore pricing
            </a>
            {' to get started.'}
          </>
        ) : (
          <>
            <Info className="h-4 text-yellow-800 w-4 mr-1" />
            Your organisation does not have an active plan. Full features are
            not available. Contact your org admin to reinstate a plan.
          </>
        )}
        <></>
      </Banner>
    </PackageProtect>
  )
}
