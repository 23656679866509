import * as React from 'react'
import { Banner, Link, useTimedContent } from 'src/design-system'
import { FreemiumBanner } from '../freemium-banner'
import { trackEvent } from '../../services/event-tracker'
import { WithAnalytics } from '../with-analytics'
import { SubscriptionBannerVm } from './subscription-banner-vm'
import { can } from '../../policies'
import { store } from 'store/index'

export type SubscriptionState =
  | 'active'
  | 'freemium'
  | 'trial'
  | 'trial-upgraded'

export type SubscriptionBannerProps = {
  subscriptionState: SubscriptionState
  source: string
  trialEndsAt: number
  children?: React.ReactNode
}

export const SubscriptionBanner: React.VFC<SubscriptionBannerProps> = (
  props
) => {
  const { subscriptionState, source, trialEndsAt } = props

  const { show, hideFor } = useTimedContent('inTrialBannerShowAgainAfter')

  const onDismissTrialBanner = () => {
    hideFor({ days: 3 })
    trackEvent('In trial banner dismissed')
  }

  const vm = new SubscriptionBannerVm(trialEndsAt)

  const user = store.nonNullCurrentUser
  const org = user.org

  if (!org) return null

  const canEditOrg = can(user, org).edit

  if (canEditOrg && ['active', 'trial-upgraded'].includes(subscriptionState)) {
    return <>{props.children}</>
  }

  return (
    <>
      {subscriptionState == 'freemium' && <FreemiumBanner />}
      {canEditOrg && subscriptionState == 'trial' && show && (
        <Banner variant="highlight" onDismiss={onDismissTrialBanner}>
          Your trial ends in {vm.distanceInDaysToTrialEnd}.{' '}
          <>
            <WithAnalytics
              event="$track_trial_banner_clicked"
              params={{ action: 'click-upgrade', source }}
            >
              <Link
                href={vm.billingLink}
                className="font-bold text-pink-700"
                data-turbo-frame="content"
                data-turbo-action="advance"
              >
                Explore pricing
              </Link>
            </WithAnalytics>
            or
            <WithAnalytics
              event="$track_trial_banner_clicked"
              params={{ action: 'click-book-call', source }}
            >
              <Link
                href={vm.bookACallLink}
                target="_blank"
                className="font-bold text-pink-700"
              >
                book a call
              </Link>
            </WithAnalytics>
            with our customer success team.
          </>
        </Banner>
      )}
    </>
  )
}
