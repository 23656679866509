import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { store } from 'store/index'

export class SubscriptionBannerVm {
  constructor(private readonly trialEndsAt: number) {}

  get distanceInDaysToTrialEnd() {
    return (
      this.trialEndsAt &&
      formatDistanceToNowStrict(new Date(this.trialEndsAt * 1000), {
        roundingMethod: 'ceil',
        unit: 'day',
      })
    )
  }

  get billingLink() {
    return `/orgs/${store.currentUser?.org?.slug}/billing/new`
  }

  get bookACallLink(): string {
    const url = new URL('https://savvycal.com/progression/product-demo')
    if (store.currentUser?.fullName)
      url.searchParams.append('display_name', store.currentUser?.fullName)
    if (store.currentUser?.email)
      url.searchParams.append('email', store.currentUser?.email)
    if (store.currentUser?.org?.name)
      url.searchParams.append('questions[0]', store.currentUser?.org?.name)

    return url.toString()
  }
}
